import Image from "next/image";
import Link from "next/link";

function Logo({ size = 1 }: { size?: number }) {
  return (
    <Link href="/dashboard">
      <a className="shrink-0 flex">
        <Image
          src="/images/equal-care-logo.webp"
          alt="Equal Care Co-op"
          title="The Equal Care Co-op Logo"
          className="p-2 h-20"
          height={60 * size}
          width={90 * size}
        />
      </a>
    </Link>
  );
}

export default Logo;
