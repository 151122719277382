import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import { signIn, useSession, UseSessionOptions } from "next-auth/react";
import { useCallback } from "react";
import { MINUTE } from "../queries/queryUnits";

export default function useSessionStore(
  opts: Partial<UseSessionOptions<true>> = {}
): ReturnType<typeof useSession> & { isInitialLoading: boolean } {
  const handleUnauthenticated = useCallback(() => {
    signIn("keycloak", { redirect: true });
  }, []);

  // use next-auth to handle redirection on page load
  useSession<true>({
    required: true,
    onUnauthenticated: handleUnauthenticated,
    ...opts,
  });

  // use react-query to handle refreshing session client-side
  // this is very important for long-lived, interactive pages
  // e.g. the calendar to continue to be useful
  const sessionQuery = useQuery(
    ["session"],
    async () => {
      console.info("fetching new session");
      const response = await fetch(`/api/auth/session`);
      if (!response.ok) throw response;

      const data: Session = await response.json();
      if (data.error) throw data;
      if (!data?.user) throw data;

      return data;
    },
    {
      cacheTime: 4 * MINUTE,
      staleTime: 4 * MINUTE,
      retry: 3,
      refetchInterval(data) {
        if (!data) return 0;
        const now = new Date().valueOf() / 1000;
        const expires = data.user.exp;
        const buffer = 60; // seconds
        const staleTimeSeconds = expires - now - buffer;
        console.debug("session refetch interval (s)", staleTimeSeconds);
        if (staleTimeSeconds < 0) return 0;
        return staleTimeSeconds * 1000;
      },
    }
  );

  // provide a useSession-compatible interface for easy interoperability
  if (sessionQuery.isLoading) {
    return {
      data: null,
      status: "loading",
      isInitialLoading: sessionQuery.isInitialLoading,
    };
  }
  if (sessionQuery.error || !sessionQuery.data) {
    if (process.env.NODE_ENV !== "test") handleUnauthenticated();
    return {
      data: null,
      status: "unauthenticated",
      isInitialLoading: sessionQuery.isInitialLoading,
    };
  }
  return {
    data: sessionQuery.data,
    status: "authenticated",
    isInitialLoading: sessionQuery.isInitialLoading,
  };
}
