import { Icon, IconProps } from "@iconify/react";
import React, { forwardRef } from "react";
import cx from "classnames";

const Spinner = forwardRef<SVGSVGElement, Omit<IconProps, "icon">>(
  function Spinner({ className, ...props }, ref) {
    return (
      <Icon
        icon="fa6-solid:spinner"
        className={cx("animate-spin", className)}
        {...props}
        ref={ref || undefined}
      />
    );
  }
);

export default Spinner;
