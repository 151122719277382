import { createContext, useContext } from "react";
import useRequiredSession from "./useRequiredSession";

export const SessionContext = createContext<
  ReturnType<typeof useRequiredSession>
>({
  data: null,
  status: "loading",
  isInitialLoading: true,
});

export default function useRequiredSessionStore() {
  return useContext(SessionContext);
}
