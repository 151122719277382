// pages/_app.js
import { SessionProvider } from "next-auth/react";
import { AppProps as NextAppProps } from "next/app";
import ErrorBoundary from "../components/ErrorBoundary";
import "../styles/globals.css";

import {
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useState } from "react";
import { Session } from "next-auth";
import { QueryParamProvider } from "use-query-params";
import ProgressBar from "next-nprogress-bar";

import AuthLayout from "../components/layouts/AuthLayout";
import { NextAdapter } from "next-query-params";
import { OutputFormat, setDefaults } from "react-geocode";

const setting = () => {
  setDefaults({
    key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    language: "en",
    region: "uk",
    outputFormat: OutputFormat.JSON,
  });
};

setting();

interface AppProps {
  session: Session;
  dehydratedState: DehydratedState;
}

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: NextAppProps<AppProps>) {
  const [queryClient] = useState(() => new QueryClient());

  // If page layout is available, use it. Else return the page
  const Layout = (Component as any).getLayout || AuthLayout;
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <SessionProvider session={session}>
          <ErrorBoundary>
            <QueryParamProvider adapter={NextAdapter}>
              <ProgressBar
                height="4px"
                color="#B00A5E"
                options={{ showSpinner: false }}
                shallowRouting
              />
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </QueryParamProvider>
          </ErrorBoundary>
        </SessionProvider>
      </Hydrate>
    </QueryClientProvider>
  );
}
