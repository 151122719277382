import React from "react";

export default class ErrorBoundary extends React.Component<
  React.PropsWithChildren<{}>,
  { hasError: boolean }
> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, { errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <p role="alert">Oops! Something went wrong.</p>;
    }

    return this.props.children;
  }
}
