import { SessionContext } from "../../lib/hooks/useRequiredSessionStore";
import useRequiredSession from "../../lib/hooks/useRequiredSession";
import React, { PropsWithChildren, useCallback, useEffect } from "react";
import Logo from "../Logo";
import Spinner from "../Spinner";
import { create } from "zustand";
import { useRouter } from "next/router";

export const useBookingSideMenuStore = create<{
  open: boolean;
  setOpen: (value: boolean) => void;
}>((set) => ({
  open: false,
  setOpen: (value: boolean) => set(() => ({ open: value })),
}));

export default function AuthLayout({ children }: PropsWithChildren) {
  const requiredSession = useRequiredSession();
  const sidebarOpen = useBookingSideMenuStore((state) => state.open);
  const setSidebarOpen = useBookingSideMenuStore(
    useCallback((state) => state.setOpen, [])
  );
  const router = useRouter();

  useEffect(() => {
    setSidebarOpen(false);
  }, [router.pathname, setSidebarOpen]);

  const isBookingPage = router.pathname.endsWith("bookings");

  useEffect(() => {
    if (isBookingPage && sidebarOpen) {
      document.body.style.cssText = "overflow: hidden;";
    } else {
      document.body.style.cssText = "";
    }
  }, [isBookingPage, sidebarOpen]);
  return (
    <SessionContext.Provider value={requiredSession}>
      {requiredSession.isInitialLoading && (
        <div className="h-screen w-screen bg-white z-10 absolute flex flex-col justify-center items-center">
          <Logo />
          <Spinner fontSize={40} className="text-secondary mt-10" />
        </div>
      )}
      {!requiredSession.isInitialLoading && children}
    </SessionContext.Provider>
  );
}
